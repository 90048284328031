import { Flex, Grid, Heading, Text } from '@iziwork/design-system';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { FC } from 'react';
import styled from 'styled-components';

import { BannerWrapper } from './BannerWrapper';

const BannerItemWrapper = styled.div`
  width: 100%;
  max-width: 284px;
  margin: ${({ theme }) => theme.space.layout.x.m} auto;
  text-align: center;
  h5 {
    margin-top: ${({ theme }) => theme.space.layout.x.m};
  }
  p {
    margin-top: ${({ theme }) => theme.space.layout.x.m};
    text-align: left;
  }
`;

const BannerHeaderWrapper = styled(Flex.Row)`
  margin: 2rem 0;
`;
const IziworkText = styled(Heading)`
  color: ${({ theme }) => theme.colors.primary.normal};
`;

export const IziBanner: FC = () => {
  const { t } = useTranslation('all');

  return (
    <BannerWrapper>
      <BannerHeaderWrapper space="s" justifyContent="center">
        <Heading textAlign="center" variant="heading-2">
          {t('banner.join_community')}
        </Heading>
        <IziworkText textAlign="center" variant="heading-2">
          iziwork
        </IziworkText>
      </BannerHeaderWrapper>
      <Grid.Wrapper>
        <Grid.Cell xs={1} s={1 / 3}>
          <BannerItemWrapper>
            <Image
              src="/images/icn-search.png"
              alt="search"
              width={48}
              height={48}
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
            <Heading variant="heading-5">{t('banner.large_choice')}</Heading>
            <Text variant="body-lighter">{t('banner.large_choice_desc')}</Text>
          </BannerItemWrapper>
        </Grid.Cell>
        <Grid.Cell xs={1} s={1 / 3}>
          <BannerItemWrapper>
            <Image
              src="/images/icn-fast.png"
              alt="fast"
              width={48}
              height={48}
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
            <Heading variant="heading-5">{t('banner.simple_and_fast')}</Heading>
            <Text variant="body-lighter">{t('banner.simple_and_fast_desc')}</Text>
          </BannerItemWrapper>
        </Grid.Cell>
        <Grid.Cell xs={1} s={1 / 3}>
          <BannerItemWrapper>
            <Image
              src="/images/icn-checked.png"
              alt="checked"
              width={48}
              height={48}
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
            <Heading variant="heading-5">{t('banner.personal_service')}</Heading>
            <Text variant="body-lighter">{t('banner.personal_service_desc')}</Text>
          </BannerItemWrapper>
        </Grid.Cell>
      </Grid.Wrapper>
    </BannerWrapper>
  );
};
