import { Heading } from '@iziwork/design-system';
import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';
import styled from 'styled-components';

interface MissionsCounterProps {
  count: number;
}

const CountMissionTypo = styled(Heading)`
  font-weight: ${({ theme }) => theme.typography.fontWeight.semibold};
  font-size: 1rem;
  margin-bottom: 1rem !important;
`;

export const MissionsCounter: FC<MissionsCounterProps> = ({ count }) => {
  const { t } = useTranslation('all');

  return (
    <CountMissionTypo id="missions_count" variant="heading-4">
      {t('welcome_page.missions_available', { count })}
    </CountMissionTypo>
  );
};
