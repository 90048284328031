import { Text } from '@iziwork/design-system';
import styled from 'styled-components';

export const MessageInfo = styled(Text)`
  color: ${({ theme }) => theme.colors.info?.normal};

  a,
  a:visited {
    color: ${({ theme }) => theme.colors.primary.normal};
    text-decoration: none;
    font-weight: 700;
  }

  a:hover {
    color: ${({ theme }) => theme.colors.primary.dark};
    text-decoration: underline;
  }

  a:active,
  a:focus {
    color: ${({ theme }) => theme.colors.primary.darker};
    text-decoration: underline;
  }
`;
