export function formatCurrency(value: number, unitRate: string, locale: string): string {
  const fractionDigits = unitRate === 'HOURLY' ? 2 : 0;

  return new Intl.NumberFormat(locale, {
    currency: 'EUR',
    style: 'currency',
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  }).format(value);
}

export function formatSalary({
  locale,
  salary: { minRate, maxRate, unitRate },
}: {
  locale: string;
  salary: { minRate: number | null; maxRate: number; unitRate: string };
}): string {
  return [minRate && formatCurrency(minRate, unitRate, locale), formatCurrency(maxRate, unitRate, locale)]
    .filter(Boolean)
    .join(' - ');
}
