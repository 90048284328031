import styled, { css } from 'styled-components';

interface ContainerProps {
  shouldHaveABackground?: boolean;
  shouldHaveABackgroundColor?: boolean;
}

export const Container = styled.div<ContainerProps>`
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  ${({ shouldHaveABackground }: ContainerProps) =>
    shouldHaveABackground &&
    css`
      background-image: linear-gradient(to bottom, #e8f1ff 12%, #feffff 44%, #ffffff 44%);
    `}
  ${({ shouldHaveABackgroundColor }: ContainerProps) =>
    shouldHaveABackgroundColor &&
    css`
      background-color: ${({ theme }) => theme.palette.grey50};
    `}
`;
