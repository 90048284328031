import { Icon, ValidityChangeCallback } from '@iziwork/design-system';
import React, { useEffect, useState } from 'react';

import { ClearIcon, Container, StyledControl, StyledOption } from './Select.style';

export interface SelectOption<T = string> {
  value: T;
  label?: string;
}

type SelectProps<T = string> = {
  required?: boolean;
  value: string | null;
  onChange?: (value: T | null) => void;
  search?: string | null;
  onSearchChange?: (search: string | null) => void;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  onBeforeInput?: () => void;
  placeholder?: string;
  options: SelectOption<T>[];
  formatLabel?: (value: T) => string;
  onValidityChange?: ValidityChangeCallback;
  onFocus?: ((e: FocusEvent) => void) | undefined;
  onInput?: ((e: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  fontSize?: 'l' | 'm';
  clearable?: boolean;
};

export const Select = <T = string,>({
  required,
  value,
  search,
  options,
  placeholder,
  onSearchChange,
  onChange,
  onBlur,
  onBeforeInput,
  formatLabel,
  onValidityChange,
  onFocus,
  onInput,
  fontSize = 'm',
  clearable,
}: SelectProps<T>) => {
  const [isDropdownOpened, setDropdownOpened] = useState(false);

  useEffect(() => {
    if (!onValidityChange) {
      return;
    }
    if (required && !value?.trim()) {
      onValidityChange({ isValid: false, reason: 'valueMissing' });
    } else {
      onValidityChange({ isValid: true, reason: undefined });
    }
  }, [value, required, onValidityChange]);

  const handleClearIconClick = () => {
    onChange(null);
  };

  return (
    <Container isOpened={isDropdownOpened && Boolean(options.length)}>
      <StyledControl
        value={value}
        onBlur={onBlur}
        search={search}
        onInput={onInput}
        onFocus={onFocus}
        onChange={onChange}
        required={required}
        fontSize={fontSize}
        $clearable={clearable}
        placeholder={placeholder}
        formatLabel={formatLabel}
        isOpened={isDropdownOpened}
        onBeforeInput={onBeforeInput}
        onSearchChange={onSearchChange}
        onOpenedChange={setDropdownOpened}
        suffix={
          <>
            {clearable && value && <ClearIcon name="clear-circle-filled" size="l" onClick={handleClearIconClick} />}
            <Icon name="down-filled" size="m" />
          </>
        }
      >
        {options.map(({ value: optionValue, label }) => (
          <StyledOption key={`option_key_${optionValue}`} value={optionValue} label={label} />
        ))}
      </StyledControl>
    </Container>
  );
};
