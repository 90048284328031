import { Grid } from '@iziwork/design-system';
import { FC } from 'react';

import { SearchJobOfferOutput } from '~/business/JobOffer/searchJobOffers';

import { MissionCard } from '.';

interface MissionsProps {
  jobOffers: SearchJobOfferOutput[];
  onMissionClick: (jobOffer: SearchJobOfferOutput) => void;
}

export const Missions: FC<MissionsProps> = ({ jobOffers, onMissionClick }) => (
  <Grid.Wrapper>
    {jobOffers.map(jobOffer => (
      <Grid.Cell key={jobOffer.refId} xs={1} s={1 / 2}>
        <MissionCard jobOffer={jobOffer} onMissionClick={onMissionClick} />
      </Grid.Cell>
    ))}
  </Grid.Wrapper>
);
