import { Card, Icon as DSIcon, Tag as DSTag, Text as DSText, Heading } from '@iziwork/design-system';
import styled from 'styled-components';

export const CardItem = styled(Card)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  box-sizing: border-box;
  min-height: 13em;
  padding: 1.5em;
`;

export const CardTitle = styled(Heading)`
  overflow-wrap: break-word;
  &:first-letter {
    text-transform: uppercase;
  }
`;

export const Icon = styled(DSIcon)`
  padding: 0.75rem;
  border-radius: 0.75rem;
  // 1A - opacity 10%
  background-color: ${({ intent, theme }) => `${theme.colors[intent as string].textRegular}1A`};
`;

export const SecondaryText = styled(DSText)`
  margin-bottom: 0 !important;
`;

export const Tag = styled(DSTag)`
  margin-bottom: 0.25rem;
`;
