import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { ComponentProps, forwardRef } from 'react';
import styled from 'styled-components';

import { Container, InnerContainer } from '~/components/Container';
import { CustomThemeOverrides } from '~/themes/default';
import { device } from '~/utils';

type Props = ComponentProps<'header'>;

const SDivHeaderWrapper = styled(InnerContainer)`
  padding-top: ${({ theme }: { theme: CustomThemeOverrides }) => theme.space.layout.y.xl};
  padding-bottom: 0;

  @media ${device.mobile} {
    display: flex;
    justify-content: center;
    padding-top: ${({ theme }: { theme: CustomThemeOverrides }) => theme.space.layout.y.xs};
  }
`;

const SLogo = styled.img`
  margin-left: 0.25rem;
  align-self: flex-start;
  object-fit: contain;
  width: 120px;
  cursor: pointer;
  @media ${device.mobile} {
    margin: 0;
  }
`;

const StyledHeader = styled.header`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
`;

export const Header = forwardRef<HTMLDivElement, Props>((_props, ref) => {
  const { locale, pathname } = useRouter();

  const getLogo = () => {
    const reg = new RegExp(`^(/${locale})?/partners|^(/${locale})?/$`);
    return reg.test(pathname) ? '/images/logo-white-iziwork-by-proman.png' : '/images/logo-iziwork-by-proman.png';
  };

  return (
    <StyledHeader ref={ref}>
      <Container>
        <SDivHeaderWrapper>
          <Link href="/">
            <SLogo src={getLogo()} alt="iziwork" />
          </Link>
        </SDivHeaderWrapper>
      </Container>
    </StyledHeader>
  );
});
