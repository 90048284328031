import { Flex, Text } from '@iziwork/design-system';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { FC, useMemo } from 'react';

import { SearchJobOfferOutput } from '~/business/JobOffer/searchJobOffers';
import { useLocale } from '~/hooks';
import { MissionUrl } from '~/utils/MissionUrl';
import { formatSalary } from '~/utils/salary';

import { CardItem, CardTitle, Icon, SecondaryText, Tag } from './MissionCard.style';

type MissionCardProps = {
  jobOffer: SearchJobOfferOutput;
  onMissionClick: (jobOffer: SearchJobOfferOutput) => void;
};

export const MissionCard: FC<MissionCardProps> = ({ jobOffer, onMissionClick }) => {
  const { locale } = useLocale();
  const { t } = useTranslation('all');

  const missionLink = useMemo(() => {
    const missionUrl = new MissionUrl(locale, jobOffer.slug);
    return missionUrl.getLocalizedMissionUrl();
  }, [locale, jobOffer.slug]);

  const distance =
    jobOffer.distance < 1 ? t('missions.close_distance') : t('missions.distance', { distance: jobOffer.distance });

  return (
    <Link href={missionLink}>
      <CardItem px="l" py="l" onClick={() => onMissionClick(jobOffer)}>
        <Flex.Column space="m">
          <CardTitle variant="heading-4">{jobOffer.title}</CardTitle>
          {jobOffer.city && (
            <Flex.Row space="s">
              <Icon size="xl" name="location-filled" intent="success" />
              <Flex.Column space="s">
                <SecondaryText variant="body" color="disabled">
                  {jobOffer.city}
                </SecondaryText>
                <Text variant="body">{distance}</Text>
              </Flex.Column>
            </Flex.Row>
          )}
          <Flex.Row space="s">
            <Icon size="xl" name="euro-filled" intent="warning" />
            <Flex.Column space="s">
              <SecondaryText variant="body" color="disabled">
                {t(`salary.gross_per_unit.${jobOffer.salary.unitRate.toLowerCase()}`)}
              </SecondaryText>
              <Text variant="body">{formatSalary({ locale, salary: jobOffer.salary })}</Text>
            </Flex.Column>
          </Flex.Row>
          <Flex.Row space="s">
            {jobOffer.urgent && <Tag variant="red" label={t('form_option_labels.urgent')} />}
            {jobOffer.type && (
              <Tag variant="grey" label={t(`form_option_labels.type.${jobOffer.type.toLowerCase()}`)} />
            )}
            {jobOffer.weeklyHours && (
              <Tag variant="grey" label={t('missions.weekly_hours.default', { hours: jobOffer.weeklyHours })} />
            )}
            {jobOffer.requiredExperience && (
              <Tag variant="grey" label={t(`tags.experience.${jobOffer.requiredExperience.toLowerCase()}`)} />
            )}
          </Flex.Row>
        </Flex.Column>
      </CardItem>
    </Link>
  );
};
