import { Flex } from '@iziwork/design-system';
import styled from 'styled-components';

import { device, displayFlexColumn } from '../../utils';
import { InnerContainer } from '../Container';

export const StyledFooter = styled.footer`
  width: 100%;
  background-color: ${props => props.theme.colors.secondary.normal};

  span,
  h4 {
    color: ${props => props.theme.colors.body.textLight};
  }

  a {
    text-decoration: none;
  }
`;

export const FlexColumnFooterWrapper = styled(InnerContainer)`
  ${displayFlexColumn}

  @media ${device.mobile} {
    align-items: center;
  }
`;

export const FooterMainBlock = styled(Flex.Column)`
  width: 100%;
`;

export const BrandLogo = styled.img`
  width: 48px;
  height: 48px;
`;
