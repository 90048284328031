import { Icon, Text } from '@iziwork/design-system';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import React from 'react';
import styled from 'styled-components';

import { DesktopOnly, MobileOnly } from '~/components/Styled';
import { getJobsPublicURL } from '~/config/jobs';
import { useTrackingContext } from '~/hooks/useTrackingContext';
import { device } from '~/utils';

const StyledBackBtn = styled.div`
  z-index: 450;
  @media ${device.mobile} {
    position: absolute;
    left: ${({ theme }) => theme.space.layout.x.l};
    top: ${({ theme }) => theme.space.layout.y.s};
  }
  @media (min-width: ${({ theme }) => theme.devices.tablet.min}) {
    transform: ${({ theme }) => `translateY(${theme.space.layout.y.m})`};
  }
`;

const StyledDesktopBtn = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  span,
  i {
    color: ${({ theme }) => theme.colors.info?.normal};
  }
  i {
    margin-right: 5px;
  }
`;

export const BackBtn: React.FC = () => {
  const { push, back } = useRouter();
  const { t } = useTranslation('all');
  const { referer } = useTrackingContext();

  const onClick = async () => {
    if (referer) {
      back();
    } else {
      await push(getJobsPublicURL());
    }
  };

  return (
    <StyledBackBtn>
      <MobileOnly>
        <Icon onClick={onClick} name="arrow-back-filled" size="m" />
      </MobileOnly>
      <DesktopOnly>
        <StyledDesktopBtn onClick={onClick}>
          <Text variant="body">
            <Icon name="arrow-back-filled" />
            {t('missions.back')}
          </Text>
        </StyledDesktopBtn>
      </DesktopOnly>
    </StyledBackBtn>
  );
};
