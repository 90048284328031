import useTranslation from 'next-translate/useTranslation';
import { useCallback, useState } from 'react';

import { AutocompleteSelect } from '~/components/AutocompleteSelect';
import { SelectOption } from '~/components/Select';
import { useSearchFormContext } from '~/components/Welcome/useSearchFormContext';
import { useCacheFromCallback, useDebouncedCallback, useLocale } from '~/hooks';
import { callIndexerProxy } from '~/utils/indexer';

type JobSelectProps = {
  fontSize?: 'l' | 'm';
};

export const JobSelect = ({ fontSize }: JobSelectProps) => {
  const { t } = useTranslation('all');
  const { country } = useLocale();
  const [options, setOptions] = useState<SelectOption[]>([]);

  const {
    form: {
      job: { value, onChange, onValidityChange },
    },
  } = useSearchFormContext();

  const getJobs = useCacheFromCallback(async (needle: string) => {
    const { data } = await callIndexerProxy<string>(country, '/jobSearch/searchForJobs', {
      needle,
    });
    return data?.results || [];
  });

  const [debounceJobs, cancelDebounceJobs] = useDebouncedCallback(async (needle: string) => {
    const jobs = await getJobs(needle);
    setOptions(jobs.map(job => ({ value: job })));
  }, 800);

  const handleSearchChange = useCallback(async (newSearch: string | null) => {
    if (newSearch?.length < 2) {
      cancelDebounceJobs();
      setOptions([]);
      return;
    }
    await debounceJobs(newSearch);
  }, []);

  return (
    <AutocompleteSelect
      clearable
      placeholder={t('form_placeholders.job')}
      allowFreeText
      value={value}
      options={options}
      fontSize={fontSize}
      onChange={onChange}
      resetSearchOnFocus={false}
      onSearchChange={handleSearchChange}
      onValidityChange={onValidityChange}
    />
  );
};
