import { Heading as DSHeading, Flex, FormFeedback as FormFeedbackDS } from '@iziwork/design-system';
import styled from 'styled-components';

import { palette, theme } from '~/themes/default';

export const HeaderWrapper = styled.div<{ isSearchApplied: boolean }>`
  width: 100%;
  height: 540px;
  overflow: hidden;
  position: relative;
  background: linear-gradient(${palette.darkBlue}, ${palette.darkerBlue});
  @media (max-width: ${theme.devices.tablet.max}) {
    height: ${({ isSearchApplied }) => (isSearchApplied ? '130px' : '520px')};
  }
  @media (max-width: ${theme.devices.mobile.max}) {
    height: ${({ isSearchApplied }) => (isSearchApplied ? '70px' : '570px')};
  }
`;

export const GridWrapper = styled(Flex.Column)`
  left: 0;
  top: 130px;
  right: 280px;
  padding: 4rem 1.5rem;
  position: absolute;
  box-sizing: border-box;
  max-width: 49rem;
  @media (max-width: ${theme.devices.tablet.max}) {
    padding: 1rem 4rem;
    right: 0;
  }
  @media (max-width: ${theme.devices.mobile.max}) {
    top: 90px;
    align-items: center;
  }
`;

export const Heading = styled(DSHeading)`
  color: ${theme.palette.white};
  font-weight: ${theme.typography.fontWeight.bold};
  font-size: 5rem;
  line-height: 5rem;
  white-space: pre;
  @media (max-width: ${theme.devices.tablet.max}) {
    white-space: initial;
    max-width: 400px;
    font-size: 4rem;
    line-height: 4rem;
  }
  @media (max-width: ${theme.devices.mobile.max}) {
    font-size: 3rem;
    line-height: 3rem;
    text-align: center;
    min-width: 320px;
  }
`;

export const HeadingSpan = styled.span`
  color: ${theme.colors.primary.normal};
`;

export const Label = styled.label`
  display: block;
  color: ${theme.palette.white};
  font-family: ${theme.typography.fontFamily.regular};
  font-weight: ${theme.typography.fontWeight.bold};
  line-height: ${theme.typography.lineHeight.m};
  padding-bottom: 0.5rem;
  @media (max-width: ${theme.devices.mobile.max}) {
    text-align: center;
  }
  @media (min-width: ${theme.devices.mobile.max}) {
    padding-left: 1.5rem;
  }
`;

export const FrontImageBackground = styled.div<{ isSearchApplied: boolean }>`
  right: 0;
  top: 55px;
  width: 350px;
  position: absolute;
  @media (max-width: ${theme.devices.tablet.max}) {
    right: 40px;
    top: 20px;
    width: 300px;
    display: ${({ isSearchApplied }) => (isSearchApplied ? 'none' : 'block')};
  }
  @media (max-width: ${theme.devices.mobile.max}) {
    display: none;
  }
`;

export const SelectorsRow = styled(Flex.Row)<{ isSearchApplied: boolean }>`
  margin-left: 0.25rem;
  margin-top: 2.5rem;
  @media (max-width: ${theme.devices.tablet.max}) {
    margin-top: 1.5rem;
    display: ${props => (props.isSearchApplied ? 'none' : 'flex')};
  }
  @media (max-width: ${theme.devices.mobile.max}) {
    flex-direction: column;
  }
`;
export const SelectColumn = styled.div`
  height: 7.25rem;
  width: 100%;
  @media (max-width: ${theme.devices.mobile.max}) {
    margin-right: 0 !important;
  }
`;

export const FormFeedback = styled(FormFeedbackDS)`
  margin-left: 1.75rem;
  margin-top: 0.25rem;
  i,
  span {
    color: ${theme.palette.red200};
  }
`;
