import { FC, useEffect, useMemo } from 'react';

import { getRecaptchaSiteKey } from '~/config';
import { Field } from '~/hooks';
import { useRecaptchaContext } from '~/hooks/useRecaptchaContext';

interface CaptchaFieldProps {
  field: Field<string>;
}

export const CaptchaField: FC<CaptchaFieldProps> = ({ field }) => {
  const recaptcha = useRecaptchaContext();
  const [, onChange, onValidityChange, onTouch] = field;
  const siteKey = useMemo(() => getRecaptchaSiteKey(), []);

  if (!siteKey) {
    return null;
  }

  useEffect(() => {
    onValidityChange({ isValid: false, reason: 'valueMissing' });
  }, []);

  useEffect(() => {
    if (recaptcha) {
      try {
        recaptcha.render('captcha', {
          action: 'apply',
          sitekey: siteKey,
          callback: (token: string) => {
            onTouch();
            onValidityChange(token ? { isValid: true, reason: null } : { isValid: false, reason: 'valueMissing' });
            onChange(token || null);
          },
        });
      } catch {
        /* empty */
      }
    }
  }, [recaptcha, siteKey]);

  return <div id="captcha" />;
};
