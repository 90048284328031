import { Flex, Grid } from '@iziwork/design-system';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { getLinks } from '~/business/App';
import { DownloadApp } from '~/components/DownloadApp';
import { useLocale } from '~/hooks';
import { device, displayNone } from '~/utils';

const FooterSocialMenuWrapper = styled(Grid.Wrapper)`
  margin-top: ${props => props.theme.space.layout.y.xs};

  @media ${device.mobile} {
    ${displayNone}
  }
`;

const SocialMediaRow = styled(Flex.Row)`
  width: 100%;
  justify-content: flex-end !important;
`;

export const FooterSocialMenu: React.FC = () => {
  const { t } = useTranslation('all');
  const { locale, country } = useLocale();
  const links = useMemo(() => getLinks(locale, country), [locale, country]);

  return (
    <FooterSocialMenuWrapper>
      <Grid.Cell xs={0} s={2 / 3}>
        <DownloadApp />
      </Grid.Cell>
      <Grid.Cell xs={0} s={1 / 3}>
        <SocialMediaRow space="s">
          {['facebook', 'twitter', 'instagram', 'linkedin'].map(media => {
            const title = t(`footer.socialMedia.${media}`);
            return (
              <a href={links[media]} target="_blank" title={title} key={media}>
                <Image src={`/images/icn-${media}.svg`} alt={title} width={36} height={36} />
              </a>
            );
          })}
        </SocialMediaRow>
      </Grid.Cell>
    </FooterSocialMenuWrapper>
  );
};
