import { useQuery } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';

import { GET_JOB_OFFER_STATIC_MAP } from '~/business/JobOffer/queries';
import type { JobOffer } from '~/business/JobOffer/types';

const MapWrapper = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.space.layout.y.m};
`;

const Img = styled.img`
  min-width: 100%;
  border-radius: 16px;
`;

export const Map: React.FC<{ jobOffer: JobOffer }> = ({ jobOffer }) => {
  const { data } = useQuery(GET_JOB_OFFER_STATIC_MAP, {
    variables: {
      jobOfferId: jobOffer.id,
    },
  });

  return data?.getJobOfferStaticMapForJobs ? (
    <MapWrapper>
      <Img src={`data:image/jpeg;base64,${data.getJobOfferStaticMapForJobs}`} alt={jobOffer.title} />
    </MapWrapper>
  ) : null;
};
