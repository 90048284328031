import { extractLocaleData } from '~/locale';

import routes from '../../routes';

export class MissionUrl {
  private readonly slug: string;
  private readonly locale: string;
  private readonly language: string;

  private missionUrl: string;
  private applyUrl: string;

  private localizedMissionUrl: string;
  private localizedApplyUrl: string;

  constructor(locale: string, slug: string) {
    this.slug = slug;
    this.locale = locale;
    this.language = extractLocaleData(locale).language;
  }

  private getApplySuffix(): string {
    return `/${routes.apply[this.language]}`;
  }

  getMissionUrl(): string {
    if (!this.missionUrl) {
      this.missionUrl = `/${routes.jobOffer[this.language]}/${this.slug}`;
    }
    return this.missionUrl;
  }

  getApplyUrl(): string {
    if (!this.applyUrl) {
      this.applyUrl = `${this.getMissionUrl()}${this.getApplySuffix()}`;
    }
    return this.applyUrl;
  }

  getLocalizedMissionUrl(): string {
    if (!this.localizedMissionUrl) {
      this.localizedMissionUrl = `/${this.locale}${this.getMissionUrl()}`;
    }
    return this.localizedMissionUrl;
  }

  getLocalizedApplyUrl(): string {
    if (!this.localizedApplyUrl) {
      this.localizedApplyUrl = `/${this.locale}${this.getApplyUrl()}`;
    }
    return this.localizedApplyUrl;
  }

  isApplyUrl(url: string): boolean {
    return url.split('?')[0].endsWith(this.getApplySuffix());
  }

  static getSearchParams(url: string): string {
    return url.includes('?') ? url.substring(url.indexOf('?')) : '';
  }
}
