import { Flex, Heading, Text as TextDS } from '@iziwork/design-system';
import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';
import styled from 'styled-components';

import { DownloadApp } from '~/components/DownloadApp';

const NoMissionsImage = styled.img`
  height: 9.75rem;
`;

const Text = styled(TextDS)`
  width: 31rem;
`;

export const NoMissionsResult: FC = () => {
  const { t } = useTranslation('all');
  return (
    <Flex.Column space="xl">
      <Flex.Row justifyContent="center" space="l">
        <NoMissionsImage src="/images/no-mission.png" alt="No missions" />
      </Flex.Row>
      <Heading textAlign="center" variant="heading-4">
        {t('no_missions_result.title')}
      </Heading>
      <Flex.Row justifyContent="center" space="xs">
        <Text textAlign="center" variant="body-lighter">
          {t('no_missions_result.subtitle')}
        </Text>
      </Flex.Row>
      <Heading textAlign="center" variant="subtitle">
        {t('no_missions_result.download_app')}
      </Heading>
      <DownloadApp justifyContent="center" />
    </Flex.Column>
  );
};
