import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { MissionUrl } from '~/utils/MissionUrl';

const useMissionUrl = (): MissionUrl => {
  const {
    query: { slug },
    locale,
  } = useRouter();

  return useMemo(() => new MissionUrl(locale, slug as string), [slug, locale]);
};

export default useMissionUrl;
