import { FormFeedback, FormField, FormLabel, TextInput } from '@iziwork/design-system';
import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';

import { useApplyFormContext } from '~/hooks/useApplyFormContext';

export const LastnameField: FC = () => {
  const { t } = useTranslation('all');
  const { form, loading } = useApplyFormContext();
  const [values, onChange, onValidityChange] = form.lastnameField ?? [];

  return (
    <FormField
      style={{ flex: 1 }}
      label={<FormLabel text={t('apply.form_lastname_label')} />}
      control={
        <TextInput
          required
          name="lastname"
          disabled={loading}
          onChange={onChange}
          value={values?.value}
          autoComplete="family-name"
          onValidityChange={onValidityChange}
          errored={!!values?.error && values?.touched}
          placeholder={t('apply.form_lastname_placeholder')}
        />
      }
      caption={
        values?.error && values?.touched ? <FormFeedback intent="danger">{values?.error}</FormFeedback> : undefined
      }
    />
  );
};
