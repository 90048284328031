import type { TAddress } from '@iziwork/l10n';

import { callParseCloudFunctionProxy } from '~/utils/parse';

interface FileData {
  data: string;
  name: string;
  size: number;
  type: string;
}

interface LocalParam {
  country?: string;
}

export interface WebParentMissionApply extends LocalParam {
  jobOfferId: string;
  source: string;
  firstname: string;
  lastname: string;
  address: TAddress;
  phone: string;
  file: FileData;
  email: string;
  recaptcha: string;
}

export const postWebParentMissionApply = async ({ country, ...body }: WebParentMissionApply): Promise<boolean> => {
  const { data } = await callParseCloudFunctionProxy<boolean>(country, 'WebParentMissionApply', body);
  return Boolean(data);
};
