import { ValidityChangeCallback } from '@iziwork/design-system';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'react-use';

import { Select, SelectOption } from '~/components/Select';

type AutocompleteSelectProps = {
  clearable?: boolean;
  required?: boolean;
  value: string | null;
  placeholder?: string;
  fontSize?: 'l' | 'm';
  options: SelectOption[];
  allowFreeText?: boolean;
  resetSearchOnFocus?: boolean;
  formatLabel?: (value: string) => string;
  onValidityChange: ValidityChangeCallback;
  onChange: (value: string | null) => void;
  onSearchChange: (search: string | null) => void;
};

export const AutocompleteSelect: FC<AutocompleteSelectProps> = ({
  clearable,
  value,
  options,
  fontSize,
  required,
  onChange,
  placeholder,
  formatLabel,
  onSearchChange,
  onValidityChange,
  allowFreeText = false,
  resetSearchOnFocus = true,
}) => {
  const [search, setSearch] = useState(null);
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    if (value === currentValue) {
      setSearch(null);
    }
  }, [value]);

  const handleFocus = useCallback(() => {
    if (resetSearchOnFocus) {
      setSearch('');
    }
  }, [resetSearchOnFocus]);

  const handleInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newSearch = e.target.value;
      setSearch(newSearch);
      onSearchChange(newSearch);
    },
    [setSearch, onSearchChange],
  );

  const handleChange = useCallback(
    (newValue: string | null) => {
      if (!resetSearchOnFocus) {
        setSearch(newValue);
      }
      setCurrentValue(newValue);
    },
    [resetSearchOnFocus, setCurrentValue],
  );

  const handleBlur = useCallback(
    (e: any) => {
      if (allowFreeText) {
        handleChange(e.target.value);
      }
      if (resetSearchOnFocus || !allowFreeText) {
        setSearch(null);
      }
    },
    [handleChange, resetSearchOnFocus, allowFreeText],
  );

  useDebounce(
    () => {
      // in case user selects item from the list, onblur is called before onchange
      // so value changes should be accumulated before calling passed onChange
      onChange(currentValue);
    },
    150,
    [currentValue],
  );

  return (
    <Select
      search={search}
      options={options}
      value={value || ''}
      onBlur={handleBlur}
      fontSize={fontSize}
      required={required}
      onFocus={handleFocus}
      onInput={handleInput}
      clearable={clearable}
      onChange={handleChange}
      placeholder={placeholder}
      formatLabel={formatLabel}
      // must keep empty function to enable the search
      onSearchChange={() => {}}
      onValidityChange={onValidityChange}
    />
  );
};
