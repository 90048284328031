import { Icon } from '@iziwork/design-system';

import { useSearchFormContext } from '~/components/Welcome/useSearchFormContext';

import { Button } from './SearchButton.styles';

type SearchButtonProps = { className?: string };

export const SearchButton = ({ className }: SearchButtonProps) => {
  const { submit } = useSearchFormContext();

  const handleSearchButtonClick = async () => {
    await submit();
  };

  return (
    <Button size="m" onClick={handleSearchButtonClick} className={className}>
      <Icon size="xl" name="search-filled" />
    </Button>
  );
};
