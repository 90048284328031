import { Divider, Flex, Heading, Icon, Text } from '@iziwork/design-system';
import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';
import styled from 'styled-components';

import { Location } from '~/business/JobOffer/types';

import { ApplyButton } from './ApplyButton';

const InfosWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const InfosTitle = styled(Heading)`
  /* @TODO change when 2.5 rem is available as spacing */
  /* margin-bottom: ${({ theme }) => theme.space.layout.x.xl}; */
  margin-bottom: 2.5rem;
`;

const ApplyButtonWrapper = styled.div`
  margin: ${({ theme }) => theme.space.layout.x.xl} 0;
`;

const InfosDivider = styled(Divider)`
  margin: ${({ theme }) => theme.space.layout.x.s} 0;
`;

const InfosFlexRow = styled(Flex.Row)`
  flex-wrap: nowrap;
  margin: ${({ theme }) => theme.space.layout.x.m} 0;
`;

interface InfosProps {
  salaryFormattedCurrency: string;
  salaryFormattedUnit: string;
  contractType: string;
  location: Location;
  weeklyHours?: number;
  onMissionApplyClick?: () => void;
}

export const Infos: FC<InfosProps> = ({
  location,
  salaryFormattedCurrency,
  salaryFormattedUnit,
  contractType,
  weeklyHours,
  onMissionApplyClick,
}) => {
  const { t } = useTranslation('all');
  return (
    <InfosWrapper>
      <InfosTitle variant="heading-4" className="infosTitle">
        {t('missions.informations')}
      </InfosTitle>
      <Text variant="body-light" as="div">
        <InfosFlexRow justifyContent="flex-start" space="m">
          <Icon size="m" name="euro-circle-outlined" />
          <span>
            {salaryFormattedCurrency} {salaryFormattedUnit}
          </span>
        </InfosFlexRow>
        <InfosDivider />
        {weeklyHours && (
          <>
            <InfosFlexRow justifyContent="flex-start" space="m">
              <Icon size="m" name="time-circle-outlined" />
              <span>{t('missions.weekly_hours.default', { hours: weeklyHours })}</span>
            </InfosFlexRow>
            <InfosDivider />
          </>
        )}
        <InfosFlexRow justifyContent="flex-start" space="m" alignItems="flex-start">
          <Icon style={{ marginTop: '0.2rem' }} size="m" name="location-outlined" />
          <div>
            <div>{location.city}</div>
            <div>{location.postalCode}</div>
          </div>
        </InfosFlexRow>
        <InfosDivider />
        <InfosFlexRow justifyContent="flex-start" space="m">
          <Icon size="m" name="work-outlined" />
          <span>{contractType}</span>
        </InfosFlexRow>
      </Text>
      <ApplyButtonWrapper>
        <ApplyButton onClick={onMissionApplyClick} />
      </ApplyButtonWrapper>
    </InfosWrapper>
  );
};
