import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { FC } from 'react';
import styled from 'styled-components';

import useMissionUrl from '~/hooks/useMissionUrl';

interface ApplyButtonProps {
  onClick?: () => void;
}

const StyledBtn = styled.span`
  background: ${({ theme }) => theme.colors.primary.normal};
  margin: 0;
  box-sizing: border-box;
  text-align: center;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 0.8125rem;
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 700;
  width: 100%;
  color: rgb(255, 255, 255);
  line-height: 1.5rem;
  padding: 1rem 2rem;
`;

export const ApplyButton: FC<ApplyButtonProps> = ({ onClick }) => {
  const { t } = useTranslation('all');
  const missionUrl = useMissionUrl();

  return (
    <Link href={missionUrl.getLocalizedApplyUrl()} replace passHref legacyBehavior>
      <StyledBtn onClick={onClick}>{t('missions.apply')}</StyledBtn>
    </Link>
  );
};
