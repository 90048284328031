import useTranslation from 'next-translate/useTranslation';
import { useCallback } from 'react';

import { getIziworkPublicURL } from '~/config/iziwork';
import { useLocale } from '~/hooks/useLocale';

export const useGetIziworkPublicUrl = () => {
  const { t } = useTranslation('all');
  const { language } = useLocale();
  return useCallback((pathKey: string) => `${getIziworkPublicURL()}/${language}/${t(`url.${pathKey}`)}`, [language]);
};
