import { Modal } from '@iziwork/design-system';
import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';

import { ApplyCloseButtonProps } from './types';

export const ApplyCloseButton: FC<ApplyCloseButtonProps> = ({ onClose, disabled, variant }) => {
  const { t } = useTranslation('all');

  return (
    <Modal.FooterButton
      disabled={!!disabled}
      hiddenInMobile
      size="l"
      variant={variant || 'secondary'}
      onClick={onClose}
    >
      {t('apply.modal_action_close')}
    </Modal.FooterButton>
  );
};
