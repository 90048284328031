import { Icon, Text } from '@iziwork/design-system';
import Image from 'next/image';
import React, { FC } from 'react';

import { BarWrapper, LinkDiv } from './MissionsPaginationBar.style';

interface MissionsPaginationBarProps {
  pageCount: number;
  currentPage: number;
  onChangePage: (targetPage: number) => void;
}

const COUNT_PAGES_IN_BAR = 5;
const COUNT_PAGES_BEFORE_SELECTED_ELT = Math.floor(COUNT_PAGES_IN_BAR / 2);

// Number displayed by the first element in the bar
const getFirstElementPageNumber = (currentPage, pageCount) => {
  if (pageCount <= COUNT_PAGES_IN_BAR) {
    return 1;
  }

  if (currentPage <= COUNT_PAGES_BEFORE_SELECTED_ELT) {
    return 1;
  }

  if (currentPage > pageCount - (COUNT_PAGES_IN_BAR - COUNT_PAGES_BEFORE_SELECTED_ELT)) {
    return pageCount - COUNT_PAGES_IN_BAR + 1;
  }

  return currentPage - COUNT_PAGES_BEFORE_SELECTED_ELT;
};

export const MissionsPaginationBar: FC<MissionsPaginationBarProps> = ({ pageCount, currentPage, onChangePage }) => {
  const onClickHandler = (targetPage: number) => {
    if (targetPage !== currentPage && targetPage >= 1 && targetPage <= pageCount) {
      onChangePage(targetPage);
    }
  };

  const buildPagesBar = () => {
    const countElements = Math.min(pageCount, COUNT_PAGES_IN_BAR);
    const firstPageNumber = getFirstElementPageNumber(currentPage, pageCount);

    return [...Array(countElements)].map((elt, index) => {
      const targetPage = firstPageNumber + index;
      const isSelected = currentPage === targetPage;
      return (
        <LinkDiv
          id={index.toString()}
          key={index}
          data-selected={isSelected}
          data-disabled={isSelected}
          onClick={() => onClickHandler(targetPage)}
        >
          <Text variant="body">{targetPage}</Text>
        </LinkDiv>
      );
    });
  };

  const buildIconBar = (id, targetPage, isDisabled, icon) => (
    <LinkDiv id={id} data-disabled={isDisabled} onClick={() => onClickHandler(targetPage)}>
      <Icon size="m" name={icon} aria-label={`Go to ${id}`} />
    </LinkDiv>
  );

  const buildImageBar = (id, targetPage, isDisabled, image) => (
    <LinkDiv id={id} data-disabled={isDisabled} onClick={() => onClickHandler(targetPage)}>
      <Image src={image} width={24} height={24} aria-label={`Go to ${id}`} alt={`Navigation to ${id} page`} />
    </LinkDiv>
  );

  return (
    <BarWrapper alignItems="center" space="s" justifyContent="center">
      {buildImageBar('first', 1, currentPage === 1, '/images/icn-fast-backward.svg')}
      {buildIconBar('previous', currentPage - 1, currentPage === 1, 'back-filled')}
      {buildPagesBar()}
      {buildIconBar('next', currentPage + 1, currentPage === pageCount, 'next-filled')}
      {buildImageBar('last', pageCount, currentPage === pageCount, '/images/icn-fast-forward.svg')}
    </BarWrapper>
  );
};
