import Head from 'next/head';
import React, { FC, ReactNode } from 'react';

import { Footer } from '~/components/Footer';
import { Header } from '~/components/Header';
import ToastContextProvider from '~/contexts/ToastContext/ToastContext';

type LayoutProps = {
  children: ReactNode;
  title: string;
  description?: string;
  socialTitle?: string;
  socialDescription?: string;
  socialImage?: string;
};

export const Layout: FC<LayoutProps> = ({
  children,
  title,
  description,
  socialTitle,
  socialDescription,
  socialImage,
}) => (
  <>
    <Head>
      <title>{title}</title>
      {description && <meta name="description" content={description} />}
      {socialTitle && (
        <>
          <meta property="og:title" content={socialTitle} />
          <meta name="twitter:title" content={socialTitle} />
        </>
      )}
      {socialDescription && (
        <>
          <meta property="og:description" content={socialDescription} />
          <meta name="twitter:description" content={socialDescription} />
        </>
      )}
      {socialImage && (
        <>
          <meta property="og:image" content={socialImage} />
          <meta name="twitter:image" content={socialImage} />
        </>
      )}
    </Head>
    <Header />
    <ToastContextProvider>{children}</ToastContextProvider>
    <Footer />
  </>
);
