import { Flex, Heading, HighlightedIcon, Text } from '@iziwork/design-system';
import React from 'react';
import styled from 'styled-components';

interface FlexIconTextSubProps {
  iconName: 'award-filled' | 'work-filled';
  iconColor: 'purple' | 'blue';
  title: string;
  subtitle: string;
}

const IWrapper = styled(Flex.Row)`
  margin-bottom: ${({ theme }) => theme.space.layout.x.m};
`;

const Subtitle = styled(Text)`
  color: ${({ theme }) => theme.colors.info?.normal};
`;

const TextWrapper = styled.div`
  max-width: calc(100% - 3.5rem);
`;

export const FlexIconTextSub: React.FC<FlexIconTextSubProps> = ({ iconName, iconColor, title, subtitle }) => (
  <IWrapper space="s" alignItems="center">
    <HighlightedIcon name={iconName} color={iconColor} />
    <TextWrapper>
      <Heading variant="heading-5">{title}</Heading>
      <Subtitle variant="body-lighter">{subtitle}</Subtitle>
    </TextWrapper>
  </IWrapper>
);
