import { Modal } from '@iziwork/design-system';
import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';

import { ApplyCloseButton } from '~/components';
import { useApplyFormContext } from '~/hooks/useApplyFormContext';

import { ApplyCloseButtonProps } from '../types';

type ApplyUnknownFooterProps = ApplyCloseButtonProps;

export const ApplyUnknownFooter: FC<ApplyUnknownFooterProps> = ({ onClose }) => {
  const { t } = useTranslation('all');
  const { loading } = useApplyFormContext();

  return (
    <Modal.Footer>
      <Modal.FooterButton disabled={loading} size="l" type="submit" form="apply_unknown_form">
        {t('apply.modal_action_apply')}
      </Modal.FooterButton>
      <ApplyCloseButton disabled={loading} onClose={onClose} />
    </Modal.Footer>
  );
};
