import styled from 'styled-components';

export const InnerContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: ${({ theme }) => theme.sizes?.maxBodyWidth};
  padding: 4rem 1.5rem;
  margin: 0 auto;
  position: relative;
  @media (max-width: ${({ theme }) => theme.devices.tablet.max}) {
    padding: 3rem 4rem;
  }
  @media (max-width: ${({ theme }) => theme.devices.mobile.max}) {
    padding: 1rem;
  }
`;
