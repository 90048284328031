import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import React from 'react';

import jobBoardPicture from '~/../public/images/picture-job-board.png';
import { InnerContainer } from '~/components';
import { AddressSelect } from '~/components/AddressSelect';
import { JobSelect } from '~/components/JobSelect';
import { SearchButton } from '~/components/SearchButton';
import { TabletAndMobileOnly } from '~/components/Styled';
import { HeaderField } from '~/components/Welcome/Header/HeaderField';
import { useSearchFormContext } from '~/components/Welcome/useSearchFormContext';
import { useEffectIfMounted } from '~/hooks';
import { useIsMobileOrTablet } from '~/hooks/useIsMobileOrTablet';
import { Params } from '~/utils/search';

import {
  FrontImageBackground,
  GridWrapper,
  HeaderWrapper,
  Heading,
  HeadingSpan,
  SelectColumn,
  SelectorsRow,
} from './styles';

export type HeaderParams = Pick<Params, 'job' | 'address'>;

interface HeaderProps extends HeaderParams {
  isSearchApplied: boolean;
}

export const Header = ({ isSearchApplied }: HeaderProps) => {
  const { t } = useTranslation('all');
  const isMobileOrTablet = useIsMobileOrTablet();

  const {
    form: {
      job: { value: job },
      address: { value: address, error: addressError },
    },
    submit,
    resetForm,
  } = useSearchFormContext();

  const pathname = usePathname();
  useEffectIfMounted(() => {
    if (pathname === '/') {
      resetForm();
    }
  }, [pathname]);

  useEffectIfMounted(async () => {
    if (isMobileOrTablet) {
      return;
    }
    if (address?.name && !addressError) {
      await submit();
    }
  }, [isMobileOrTablet, address?.name, addressError, job]);

  return (
    <HeaderWrapper isSearchApplied={isSearchApplied}>
      <InnerContainer>
        <FrontImageBackground
          role="img"
          title="Background picture"
          aria-label="Background picture"
          isSearchApplied={isSearchApplied}
        >
          <Image
            alt=""
            priority
            width={382}
            height={500}
            src={jobBoardPicture}
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        </FrontImageBackground>
        <GridWrapper space="xs">
          <Heading variant="heading-1">
            {`${t('welcome_page.title_our_offers_near')} `}
            <HeadingSpan>{t('welcome_page.title_at_your_place')}</HeadingSpan>
          </Heading>
          <SelectorsRow space="xl" flexWrap="nowrap" isSearchApplied={isSearchApplied}>
            <SelectColumn>
              <HeaderField type="address">
                <AddressSelect fontSize="l" />
              </HeaderField>
            </SelectColumn>
            <SelectColumn>
              <HeaderField type="job">
                <JobSelect fontSize="l" />
              </HeaderField>
            </SelectColumn>
            <TabletAndMobileOnly>
              <SearchButton />
            </TabletAndMobileOnly>
          </SelectorsRow>
        </GridWrapper>
      </InnerContainer>
    </HeaderWrapper>
  );
};
