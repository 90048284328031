import styled from 'styled-components';

import { tabletAndMobileOnlyMixin } from '~/utils';

export const DesktopOnly = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.devices.tablet.min}) {
    display: block;
  }
`;

export const MobileOnly = styled.div`
  display: block;

  @media (min-width: ${({ theme }) => theme.devices.tablet.min}) {
    display: none;
  }
`;

export const TabletAndMobileOnly = styled.div`
  ${tabletAndMobileOnlyMixin}
`;
