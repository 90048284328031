import useTranslation from 'next-translate/useTranslation';
import React, { PropsWithChildren } from 'react';

import { FormFeedback, Label } from '~/components/Welcome/Header/styles';
import { useSearchFormContext } from '~/components/Welcome/useSearchFormContext';

export type HeaderFieldType = 'job' | 'address';

interface HeaderFieldProps {
  type: HeaderFieldType;
}

export const HeaderField = ({ type, children }: PropsWithChildren<HeaderFieldProps>) => {
  const { t } = useTranslation('all');
  const { form, submitted } = useSearchFormContext();

  const error = form[type]?.error;

  return (
    <>
      <Label>{t(`form_labels.${type}`)}</Label>
      {children}
      {submitted && error && <FormFeedback intent="danger">{t(`form_errors.${type}`)}</FormFeedback>}
    </>
  );
};
