// eslint-disable-next-line no-restricted-imports
import { Place } from '@backend/lib-aws-location';
// eslint-disable-next-line no-restricted-imports
import axios from 'axios';
import { alpha3ToAlpha2 } from 'i18n-iso-countries';

import { getAddressApiUrl } from '~/config';
import { useCacheFromCallback } from '~/hooks/useCacheFromCallback';
import { useLocale } from '~/hooks/useLocale';
import { AddressWithNameAndLocation } from '~/utils/address';

export const useGetAddressDetails = () => {
  const { country } = useLocale();
  return useCacheFromCallback(async id => {
    try {
      const { data: place } = await axios.post<Place | null>(getAddressApiUrl(country, 'getDetails'), {
        id,
      });
      if (place) {
        return placeToAddressWithCityAndLocation(place);
      }
    } catch (e) {
      console.error(e);
    }
    return null;
  });
};

export const placeToAddressWithCityAndLocation = (place: Place): AddressWithNameAndLocation => ({
  streetNumber: place.streetNumber,
  streetName: place.streetName,
  street: [place.streetNumber, place.streetName].filter(Boolean).join(' '),
  country: alpha3ToAlpha2(place.countryIso3Code),
  city: place.city,
  name: place.city,
  zipcode: place.zipcode,
  location: {
    latitude: place.location.latitude,
    longitude: place.location.longitude,
  },
});
