import { Toast, Toaster } from '@iziwork/design-system';
import { BannerVariant } from '@iziwork/design-system/dist/components/Banner/component';
import { createContext, ReactNode, useState } from 'react';

import type { OnClose, SetToastType, ToastContextType, ToastType } from '~/contexts/ToastContext/types';

export const ToastContext = createContext<ToastContextType>(null);

export default function ToastContextProvider(props: { children: ReactNode }) {
  const [toast, setToast] = useState<ToastType>(null);

  const _setToast = (heading: string, body: string, onClose: OnClose, variant: BannerVariant) => {
    setToast({ heading, body, onClose, variant });
  };

  const setToastInfo: SetToastType = (body, { heading = '', onClose = null } = {}) => {
    _setToast(heading, body, onClose, 'info');
  };

  const setToastSuccess: SetToastType = (body, { heading = '', onClose = null } = {}) => {
    _setToast(heading, body, onClose, 'success');
  };

  const setToastWarning: SetToastType = (body, { heading = '', onClose = null } = {}) => {
    _setToast(heading, body, onClose, 'warning');
  };

  const setToastError: SetToastType = (body, { heading = '', onClose = null } = {}) => {
    _setToast(heading, body, onClose, 'error');
  };

  const closeToast = () => setToast(null);

  return (
    <ToastContext.Provider value={{ setToastInfo, setToastSuccess, setToastWarning, setToastError, closeToast }}>
      {props.children}
      {toast ? (
        <Toaster>
          <Toast
            variant={toast.variant}
            heading={toast.heading}
            body={toast.body}
            onClose={() => {
              if (toast.onClose) {
                toast.onClose();
              }
              setToast(null);
            }}
          />
        </Toaster>
      ) : null}
    </ToastContext.Provider>
  );
}
