import { ModalStates } from '~/contexts/ApplyFormContext/enums';
import { ModalState } from '~/contexts/ApplyFormContext/types';
import { Field } from '~/hooks';
import { AddressWithNameAndLocation } from '~/utils/address';

export const EMPTY_STATE: ModalState = {
  Header: null,
  Body: null,
  Footer: null,
  id: null,
};

export const DEFAULT_CONTEXT = {
  states: {
    [ModalStates.START]: { ...EMPTY_STATE },
    [ModalStates.UNKNOWN]: { ...EMPTY_STATE },
    [ModalStates.APPLIED]: { ...EMPTY_STATE },
  },
  State: { ...EMPTY_STATE },
  error: null as Error,
  loading: false,
  setState: (_state: ModalState): void => undefined,
  setError: (_error: Error): void => undefined,
  setLoading: (_loading: boolean): void => undefined,
  form: {
    firstnameField: undefined as Field<string>,
    lastnameField: undefined as Field<string>,
    phoneField: undefined as Field<string>,
    addressField: undefined as Field<AddressWithNameAndLocation>,
    resumeField: undefined as Field<File>,
    emailField: undefined as Field<string>,
    captchaField: undefined as any,
    touchAll: (): void => undefined,
  },
};
