import { Flex } from '@iziwork/design-system';
import { Property } from 'csstype';
import Image from 'next/image';
import Link from 'next/link';
import { useMemo } from 'react';

import { getLinks } from '~/business/App';
import { useLocale } from '~/hooks';

type Props = {
  justifyContent?: Property.JustifyContent;
};

export const DownloadApp = ({ justifyContent }: Props) => {
  const { locale, country } = useLocale();
  const links = useMemo(() => getLinks(locale, country), [locale, country]);

  return (
    <Flex.Row space="m" justifyContent={justifyContent}>
      <Link href={links.apple_store}>
        <Image src={`/images/brand-badge-app-store-${locale}.svg`} alt="App Store" width={127} height={40} />
      </Link>
      <Link href={links.google_store}>
        <Image src={`/images/brand-badge-google-play-${locale}.svg`} alt="Google Play" width={136} height={40} />
      </Link>
    </Flex.Row>
  );
};
