import styled from 'styled-components';

import { device, displayFlexRow, displayNone } from '~/utils';

export const AdaptiveFlex = styled.div`
  ${displayFlexRow}
  width: 100%;
  padding-top: ${props => props.theme.space.layout.y.l};
  margin-bottom: ${props => props.theme.space.layout.y.m};
  justify-content: space-between;

  a {
    text-decoration: none;
  }

  @media ${device.mobile} {
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
`;

export const CopyrightRow = styled.div`
  width: 100%;

  @media (max-width: ${({ theme }) => theme.devices.tablet.max}) {
    margin-top: 50px;
  }

  @media (max-width: ${({ theme }) => theme.devices.mobile.max}) {
    margin-top: 70px;
  }

  > * {
    color: ${({ theme }) => theme.palette.grey400} !important;
    font-size: 0.65rem !important;
  }
`;

export const MobileLogoWrapper = styled.div`
  ${displayNone}

  @media ${device.mobile} {
    display: block;
    margin: ${props => props.theme.space.layout.y.xl} auto ${props => props.theme.space.layout.y.m};
    max-width: 36px;
    max-height: 36px;

    img {
      max-width: 36px;
      max-height: 36px;
    }
  }
`;

export const SubMenuItem = styled.div`
  cursor: pointer;
  &:not(:last-child) {
    margin-right: ${props => props.theme.space.layout.y.xs};
  }

  @media ${device.mobile} {
    &:not(:last-child) {
      margin: 0 0 ${props => props.theme.space.layout.y.xs} 0;
    }
  }
`;
