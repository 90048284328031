import { Flex, Modal } from '@iziwork/design-system';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { FC } from 'react';

import { MobileOnly } from '../../Styled';

export const ApplyAppliedHeader: FC = () => {
  const { t } = useTranslation('all');

  return (
    <>
      <MobileOnly>
        <Flex.Row space="m" justifyContent="center">
          <Image
            src="/images/paper-plane.jpg"
            alt=""
            width={357}
            height={150}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </Flex.Row>
      </MobileOnly>

      <Modal.Header title={t('apply.title_congratulation')} />
    </>
  );
};
