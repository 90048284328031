// eslint-disable-next-line no-restricted-imports
import axios, { AxiosRequestConfig } from 'axios';

import { getJobsPublicApiEndpoint } from '~/config/jobs';

export const callIndexerProxy = async <T = any>(
  country: string,
  path: string,
  body?: any,
  config?: AxiosRequestConfig,
) => {
  const url = `${getJobsPublicApiEndpoint(country)}/indexer/${path}`;
  // used in the front, cannot use the axiosClient
  return axios.post<{ results: T[]; count: number }>(url, body, config);
};
