import { callParseCloudFunctionProxy } from '~/utils/parse';

interface LocalParam {
  country?: string;
}

export interface WebParentMissionApplyIfWorkerExistsParams extends LocalParam {
  jobOfferId: string;
  email: string;
  source: string;
  recaptcha: string;
}

interface Worker {
  id: string;
  hasApp: boolean;
}

export const postWebParentMissionApplyIfWorkerExists = async ({
  country,
  ...body
}: WebParentMissionApplyIfWorkerExistsParams): Promise<Worker | undefined> => {
  const { data } = await callParseCloudFunctionProxy<Worker | undefined>(
    country,
    'WebParentMissionApplyIfWorkerExists',
    body,
  );
  return data;
};
