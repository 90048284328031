import { Divider, Flex, Grid, Heading } from '@iziwork/design-system';
import styled from 'styled-components';

import { device } from '~/utils';

export const FooterMenuCell = styled(Grid.Cell)`
  height: 100%;

  @media ${device.mobile} {
    justify-content: center;

    > ${Flex.Column} {
      width: 100%;
    }
  }
`;

export const FooterHeading = styled(Heading)`
  @media ${device.mobile} {
    text-align: center;
  }
`;

export const LinksColumn = styled(Flex.Column)`
  @media ${device.mobile} {
    align-items: center;
  }
`;

export const ColumnDivider = styled(Divider)`
  display: none !important;

  @media ${device.mobile} {
    display: block;
    color: rgba(255, 255, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.1);
  }
`;
