import { Icon, Select } from '@iziwork/design-system';
import styled from 'styled-components';

import { CustomThemeOverrides, palette } from '~/themes/default';

const containerBorderRadius = (theme: CustomThemeOverrides, quantity: number) =>
  `${new Array(quantity).fill(theme.radiuses.xxl).join(' ')}`;

export const Container = styled.div<{
  isOpened: boolean;
}>`
  position: relative;
  background-color: ${({ theme, isOpened }) => (isOpened ? theme.designTokens.controlBackgroundColor : 'transparent')};
  border-radius: ${({ theme, isOpened }) =>
    isOpened ? `${containerBorderRadius(theme, 2)} 0 0` : containerBorderRadius(theme, 4)};
  & div:focus-within,
  & div.focus-within {
    box-shadow: none;
  }
  div > div > i {
    margin-right: 0.5rem;
  }
`;

export const StyledControl = styled(Select.Control)<{
  fontSize: 'l' | 'm';
  $clearable: boolean;
}>`
  box-sizing: border-box;
  padding-left: 2rem;
  padding-right: ${({ $clearable }) => `${$clearable ? '4' : '2.25'}rem;`};
  color: ${palette.darkBlue};
  height: 3.625rem;
  line-height: ${({ theme }) => theme.typography.lineHeight.xxl};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  font-size: ${({ theme, fontSize }) => theme.typography.fontSize[fontSize]};
  border-radius: ${({ theme }) => theme.radiuses.xxl};
  border: 0;
  :focus {
    box-shadow: none;
  }
`;

export const StyledOption = styled(Select.Option)`
  padding: 0.5rem 0;
  margin-left: 2rem;
  flex-wrap: nowrap;
  :not(:first-child) {
    border-top: 1px solid ${({ theme }) => theme.palette.grey200};
  }
  :hover,
  &.hover,
  :focus,
  &.focus {
    background: ${({ theme }) => theme.palette.white};
  }
  small {
    overflow: hidden;
  }
  span {
    color: ${palette.darkBlue};
    font-weight: ${({ theme }) => theme.typography.fontWeight.semibold};
    text-overflow: ellipsis;
    overflow: hidden;
  }
  // dropdown (Overlay control from design system)
  div:has(> &) {
    border-radius: 0 0 1.5rem 1.5rem;
    box-shadow: 0 0.25rem 0.5rem -0.5rem ${({ theme }) => theme.palette.black};
    max-height: 16rem; // overlay height to make dropdown scrollable
    overflow-y: scroll;
    scrollbar-gutter: stable;

    ::-webkit-scrollbar {
      width: 32px;
    }

    ::-webkit-scrollbar-thumb {
      background: #d9d9d9;
      border-radius: 32px;
      border: solid 12px transparent;
      background-clip: content-box;
    }
  }

  @-moz-document url-prefix() {
    margin-right: 2rem;
  }
  // Safari
  @supports (-webkit-hyphens: none) {
    margin-right: 2rem;
  }
`;

export const ClearIcon = styled(Icon)`
  color: ${({ theme }) => theme.palette.grey400};
  pointer-events: auto;
  cursor: pointer;
  font-size: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
`;
