import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';

import { checkIfStringContainsScriptTags } from '~/utils';

import { FlexIconTextSub } from './FlexIconTextSub';
import { DescriptionHeading, DescriptionItem, DescriptionText, DescriptionWrapper } from './styled';

interface DescriptionProps {
  missionIntro: string;
  missionDesc: string;
  requiredCertificates?: string;
  requiredExperience?: string;
  missionBonuses?: string;
  missionPrerequisites?: string;
  missionLegalInfos?: string;
}

export const Description: FC<DescriptionProps> = ({
  missionIntro,
  missionDesc,
  requiredCertificates,
  requiredExperience,
  missionBonuses,
  missionPrerequisites,
  missionLegalInfos,
}) => {
  const { t } = useTranslation('all');

  return (
    <DescriptionWrapper>
      {missionIntro ? (
        <DescriptionItem>
          <DescriptionText
            dangerouslySetInnerHTML={{ __html: checkIfStringContainsScriptTags(missionIntro) ? '' : missionIntro }}
          />
        </DescriptionItem>
      ) : null}
      <DescriptionItem>
        <DescriptionHeading className="desc-heading" variant="heading-4">
          {t('missions.about')}
        </DescriptionHeading>
        <DescriptionText
          dangerouslySetInnerHTML={{ __html: checkIfStringContainsScriptTags(missionDesc) ? '' : missionDesc }}
        />
      </DescriptionItem>
      {missionBonuses ? (
        <DescriptionItem>
          <DescriptionHeading className="desc-heading" variant="heading-4">
            {t('missions.bonuses')}
          </DescriptionHeading>
          <DescriptionText
            dangerouslySetInnerHTML={{ __html: checkIfStringContainsScriptTags(missionBonuses) ? '' : missionBonuses }}
          />
        </DescriptionItem>
      ) : null}
      <DescriptionItem>
        <DescriptionHeading className="desc-heading" variant="heading-4">
          {t('missions.profile')}
        </DescriptionHeading>
        {missionPrerequisites ? (
          <>
            <DescriptionText
              dangerouslySetInnerHTML={{
                __html: checkIfStringContainsScriptTags(missionPrerequisites) ? '' : missionPrerequisites,
              }}
            />
            <br />
          </>
        ) : null}
        <FlexIconTextSub
          title={t('missions.certificates')}
          subtitle={requiredCertificates || t('missions.no_certificates_required')}
          iconName="award-filled"
          iconColor="purple"
        />
        <FlexIconTextSub
          title={t('missions.experience')}
          subtitle={requiredExperience || t('missions.no_experience_required')}
          iconName="work-filled"
          iconColor="blue"
        />
        {missionLegalInfos ? (
          <>
            <DescriptionText
              dangerouslySetInnerHTML={{
                __html: checkIfStringContainsScriptTags(missionLegalInfos) ? '' : missionLegalInfos,
              }}
            />
            <br />
          </>
        ) : null}
      </DescriptionItem>
    </DescriptionWrapper>
  );
};
