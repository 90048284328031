import { Flex } from '@iziwork/design-system';
import useTranslation from 'next-translate/useTranslation';
import React, { FC } from 'react';

import { Location } from '~/business/JobOffer/types';
import { BackBtn } from '~/components';
import { useLocale } from '~/hooks';
import { getDurationStringUntilNow } from '~/utils';

import {
  GreyDot,
  HeaderCard,
  HeaderCardContainer,
  HeaderCardIcon,
  HeaderCardText,
  HeaderRow,
  HeaderSubtitle,
  HeaderTitle,
  HeaderWrapper,
  TitleWrapper,
} from './styled';

interface HeaderProps {
  missionTitle: string;
  salaryFormattedCurrency: string;
  salaryFormattedUnit: string;
  publishedAt: Date;
  refId: string;
  location: Location;
  weeklyHours?: number;
}

export const Header: FC<HeaderProps> = ({
  missionTitle,
  salaryFormattedCurrency,
  salaryFormattedUnit,
  location,
  refId,
  publishedAt,
  weeklyHours,
}) => {
  const { language } = useLocale();
  const { t } = useTranslation('all');
  return (
    <HeaderWrapper>
      <TitleWrapper>
        <BackBtn />
        <HeaderTitle variant="heading-1">
          {missionTitle}
          <Flex.Row space="s" justifyContent="center" alignItems="center">
            <HeaderSubtitle variant="body">{`${t('missions.published')} ${getDurationStringUntilNow(
              publishedAt,
              t,
              language,
            )}`}</HeaderSubtitle>
            <GreyDot />
            <HeaderSubtitle variant="body">{`Ref : ${refId}`}</HeaderSubtitle>
          </Flex.Row>
        </HeaderTitle>
      </TitleWrapper>
      <HeaderRow space="m">
        <HeaderCard>
          <HeaderCardContainer space="xl" alignItems="center">
            <HeaderCardIcon src="/images/icn-dollars.png" alt="dollars" width={36} height={36} />
            <HeaderCardText text={salaryFormattedCurrency} sub={salaryFormattedUnit} size="s" />
          </HeaderCardContainer>
        </HeaderCard>
        {weeklyHours && (
          <HeaderCard>
            <HeaderCardContainer space="xl" alignItems="center">
              <HeaderCardIcon src="/images/icn-hours.png" alt="hours" width={36} height={36} />
              <HeaderCardText
                text={t('missions.weekly_hours.hours', { hours: weeklyHours })}
                sub={t('missions.weekly_hours.per_week')}
                size="s"
              />
            </HeaderCardContainer>
          </HeaderCard>
        )}
        <HeaderCard>
          <HeaderCardContainer space="s" alignItems="center">
            <HeaderCardIcon src="/images/icn-location.png" alt="location" width={36} height={36} />
            <HeaderCardText text={location.city} sub={location.postalCode} size="s" />
          </HeaderCardContainer>
        </HeaderCard>
      </HeaderRow>
    </HeaderWrapper>
  );
};
