import { Modal } from '@iziwork/design-system';
import { FC } from 'react';

import { ApplyCloseButton } from '../ApplyCloseButton';
import { ApplyCloseButtonProps } from '../types';

type ApplyAppliedFooterProps = ApplyCloseButtonProps;

export const ApplyAppliedFooter: FC<ApplyAppliedFooterProps> = ({ onClose }) => (
  <Modal.Footer>
    <ApplyCloseButton onClose={onClose} variant="primary" />
  </Modal.Footer>
);
