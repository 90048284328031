import { Flex } from '@iziwork/design-system';
import styled from 'styled-components';

export const BarWrapper = styled(Flex.Row)`
  width: 100%;
  padding: 1rem 0 2rem;
`;

export const LinkDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  background-color: ${props => (props['data-selected'] ? '#4fb767' : '#ffffff')};
  cursor: ${props => (props['data-disabled'] ? 'default' : 'pointer')};
  :hover {
    background-color: ${props => (!props['data-selected'] && !props['data-disabled'] ? '#ccffcc' : '')};
    opacity: ${props => (!props['data-selected'] && !props['data-disabled'] ? 0.6 : 1)};
  }
  i,
  img {
    opacity: ${props => (props['data-disabled'] ? 0.4 : 1)};
  }
  span {
    color: ${props => (props['data-selected'] ? '#ffffff' : '#000000')};
  }
  &:first-of-type {
    margin-bottom: 1px;
  }
`;
