import { Grid } from '@iziwork/design-system';
import React, { ComponentProps, forwardRef } from 'react';

import { Container } from '../Container';

import { BrandLogo, FlexColumnFooterWrapper, FooterMainBlock, StyledFooter } from './styled';
import { FooterLegalMenu, FooterMenu, FooterSocialMenu } from './subcomponents';

export const Footer = forwardRef<HTMLDivElement, ComponentProps<'footer'>>((props, ref) => (
  <StyledFooter ref={ref}>
    <Container>
      <FlexColumnFooterWrapper>
        <Grid.Wrapper>
          <Grid.Cell xs={0} m={1 / 10}>
            <BrandLogo src="/images/icon-app-v2.svg" alt="Iziwork Logo" />
          </Grid.Cell>
          <Grid.Cell xs={1} m={9 / 10}>
            <FooterMainBlock space="m">
              <FooterMenu />
              <FooterSocialMenu />
            </FooterMainBlock>
          </Grid.Cell>
        </Grid.Wrapper>
        <FooterLegalMenu />
      </FlexColumnFooterWrapper>
    </Container>
  </StyledFooter>
));
