import { openConsentManager } from '@iziwork/consent-manager';
import { Text } from '@iziwork/design-system';
import inEU from '@segment/in-eu';
import useTranslation from 'next-translate/useTranslation';
import React, { useEffect, useState } from 'react';

import { useLocale } from '~/hooks';
import { useGetIziworkPublicUrl } from '~/hooks/useGetIziworkPublicUrl';

import { BrandLogo } from '../../styled';

import { AdaptiveFlex, CopyrightRow, MobileLogoWrapper, SubMenuItem } from './styled';

export const FooterLegalMenu: React.FC = () => {
  const { locale } = useLocale();
  const { t } = useTranslation('all');
  const [isInEU, setIsInEU] = useState(false);
  const getIziworkPublicUrl = useGetIziworkPublicUrl();

  useEffect(() => {
    setIsInEU(inEU());
  }, []);

  return (
    <>
      <AdaptiveFlex>
        <SubMenuItem>
          <Text variant="body-lighter">© iziwork</Text>
        </SubMenuItem>
        <SubMenuItem>
          <a href={getIziworkPublicUrl('usageConditions')}>
            <Text variant="body-lighter">{t('footer.usageConditions')}</Text>
          </a>
        </SubMenuItem>
        <SubMenuItem>
          <a href={getIziworkPublicUrl('legalMentions')}>
            <Text variant="body-lighter">{t('footer.legalMentions')}</Text>
          </a>
        </SubMenuItem>
        <SubMenuItem>
          <a href={getIziworkPublicUrl('confidentiality')}>
            <Text variant="body-lighter">{t('footer.confidentiality')}</Text>
          </a>
        </SubMenuItem>
        {locale === 'fr' && (
          <SubMenuItem>
            <a href={getIziworkPublicUrl('cookie')}>
              <Text variant="body-lighter">{t('footer.cookies_policy')}</Text>
            </a>
          </SubMenuItem>
        )}
        {isInEU && (
          <SubMenuItem>
            <Text variant="body-lighter" color="body-light" onClick={openConsentManager}>
              {t('footer.cookies_settings')}
            </Text>
          </SubMenuItem>
        )}
        <SubMenuItem>
          <a href={getIziworkPublicUrl('personalData')}>
            <Text variant="body-lighter">{t('footer.personalData')}</Text>
          </a>
        </SubMenuItem>
      </AdaptiveFlex>
      <MobileLogoWrapper>
        <BrandLogo src="/images/icon-app-v2.svg" alt="Iziwork Logo" />
      </MobileLogoWrapper>
      <CopyrightRow>
        <Text variant="caption">Copyright © {new Date().getFullYear()} iziwork</Text>
      </CopyrightRow>
    </>
  );
};
