import { Card, CellTextSub, Flex, Heading, Text } from '@iziwork/design-system';
import Image from 'next/image';
import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.space.layout.x.xl} 0;
  margin: ${({ theme }) => theme.space.layout.x.xl} auto;
  margin-bottom: 0;
  @media (min-width: ${({ theme }) => theme.devices.tablet.min}) {
    margin: ${({ theme }) => theme.space.layout.x.xl} auto;
    padding: ${({ theme }) => `${theme.space.layout.x.xl} 0 ${2 * theme.space.layout.x.xl} 0`};
  }
`;

export const TitleWrapper = styled.div`
  margin: ${({ theme }) => theme.space.layout.y.m} auto;
  text-align: center;
  h1 {
    margin: 0;
    margin-bottom: ${({ theme }) => theme.space.layout.x.s};
  }
`;

export const GreyDot = styled.span`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.info?.normal};
`;

export const HeaderRow = styled(Flex.Row)`
  flex-wrap: nowrap;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.devices.mobile.max}) {
    flex-wrap: wrap;
  }
`;

export const HeaderCard = styled(Card)`
  max-width: 100%;
  background: white;
  box-sizing: border-box;
  @media (max-width: ${({ theme }) => theme.devices.mobile.max}) {
    margin: 0 0.5rem 1rem !important;
  }
  @media (min-width: ${({ theme }) => theme.devices.tablet.min}) {
    min-width: 166px;
  }
`;

export const HeaderCardContainer = styled(Flex.Row)`
  flex-wrap: nowrap;
  // mobile
  @media (max-width: ${({ theme }) => theme.devices.mobile.max}) {
    justify-content: center;
    text-align: center;
  }
`;

export const HeaderCardIcon = styled(Image)`
  margin-right: 0 !important;
`;

export const HeaderCardText = styled(CellTextSub)`
  overflow: hidden;
  margin-left: ${({ theme }) => theme.space.layout.x.m};
`;

export const HeaderTitle = styled(Heading)`
  max-width: 720px;
  margin: 0 auto !important;
`;

export const HeaderSubtitle = styled(Text)`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.info?.normal};
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.16px;
  margin-top: 0;
`;
