/**
 * ROUTE TERMS TO REWRITES
 *
 * URL will be generated as `offre-emploi/:slug` from FR and `offerta-lavoro/:slug` for IT
 * /!\ We do not use lokalise to translate routing because update this wording is very sensitive due to SEO
 * /!\ if you change jobOffer here you need to also change into apple-app-site-association
 * We can add new terms, and new countries without restriction
 * This is used by `next.config.js` and may be used to generate urls from locale
 */
module.exports = {
  jobOffer: {
    fr: 'offre-emploi',
    it: 'offerta-lavoro',
  },
  apply: {
    fr: 'postuler',
    it: 'candidati',
  },
  browse: {
    fr: 'parcourir',
    it: 'cerca',
  },
};
