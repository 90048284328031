import React, { createContext, ReactNode, useCallback, useMemo, useState } from 'react';

import {
  ApplyAppliedBody,
  ApplyAppliedFooter,
  ApplyAppliedHeader,
  ApplyStartBody,
  ApplyStartFooter,
  ApplyStartHeader,
  ApplyUnknownBody,
  ApplyUnknownFooter,
  ApplyUnknownHeader,
} from '~/components/ApplyForm';
import { ModalStates } from '~/contexts/ApplyFormContext/enums';
import { ModalState } from '~/contexts/ApplyFormContext/types';
import { useField } from '~/hooks';
import { AddressWithNameAndLocation } from '~/utils/address';

import { DEFAULT_CONTEXT } from './constants';

export const ApplyFormContext = createContext(DEFAULT_CONTEXT);

export function ApplyFormContextProvider(props: { children: ReactNode }) {
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const firstnameField = useField<string>('firstname');
  const lastnameField = useField<string>('lastname');
  const phoneField = useField<string>('phone');
  const addressField = useField<AddressWithNameAndLocation>('address');
  const resumeField = useField<File | null>('resume');
  const emailField = useField<string>('email');
  const captchaField = useField<string>('captcha');

  const states = useMemo(
    () => ({
      [ModalStates.START]: {
        Header: ApplyStartHeader,
        Body: ApplyStartBody,
        Footer: ApplyStartFooter,
        id: 'modal_step_email',
      },
      [ModalStates.UNKNOWN]: {
        Header: ApplyUnknownHeader,
        Body: ApplyUnknownBody,
        Footer: ApplyUnknownFooter,
        id: 'modal_step_details',
      },
      [ModalStates.APPLIED]: {
        Header: ApplyAppliedHeader,
        Body: ApplyAppliedBody,
        Footer: ApplyAppliedFooter,
        id: 'modal_step_confirmation',
      },
    }),
    [
      ApplyAppliedBody,
      ApplyAppliedFooter,
      ApplyAppliedHeader,
      ApplyStartHeader,
      ApplyStartBody,
      ApplyStartFooter,
      ApplyUnknownBody,
      ApplyUnknownHeader,
      ApplyUnknownFooter,
    ],
  );
  const [currentState, setState] = useState<ModalState>(states[ModalStates.START]);
  const touchAll = useCallback(() => {
    firstnameField?.[3]?.();
    lastnameField?.[3]?.();
    phoneField?.[3]?.();
    addressField?.[3]?.();
    resumeField?.[3]?.();
    emailField?.[3]?.();
    captchaField?.[3]?.();
  }, [firstnameField, lastnameField, phoneField, addressField, resumeField, emailField, captchaField]);

  return (
    <ApplyFormContext.Provider
      value={{
        ...DEFAULT_CONTEXT,
        State: currentState,
        states,
        error,
        loading,
        setState,
        setError,
        setLoading,
        form: {
          firstnameField,
          lastnameField,
          phoneField,
          addressField,
          resumeField,
          emailField,
          captchaField,
          touchAll,
        },
      }}
    >
      {props.children}
    </ApplyFormContext.Provider>
  );
}
