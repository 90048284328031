import useUTM from '~/hooks/useUTM';
import { getEnv } from '~/utils/env';

const SOURCE = 'goldenbees';

export const useGoldenBees = (): (() => void) => {
  const utm = useUTM();

  const getGBTag = async () => {
    if (!globalThis.gbTag && getEnv('NEXT_PUBLIC_GOLDEN_BEES_URL') && getEnv('NEXT_PUBLIC_GOLDEN_BEES_KEY')) {
      await new Promise<void>(resolve => {
        const gbJsScript = document.createElement('script');
        gbJsScript.src = getEnv('NEXT_PUBLIC_GOLDEN_BEES_URL');
        document.head.appendChild(gbJsScript);
        gbJsScript.addEventListener('load', () => {
          globalThis.gbTag = globalThis.GbTagBuilder.build(getEnv('NEXT_PUBLIC_GOLDEN_BEES_KEY'));
          resolve();
        });
      });
    }
    return globalThis.gbTag;
  };

  return async () => {
    if (utm.utmSource === SOURCE) {
      const gbTag = await getGBTag();
      gbTag?.fire();
    }
  };
};
