import { Modal } from '@iziwork/design-system';
import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';

export const ApplyStartHeader: FC = () => {
  const { t } = useTranslation('all');

  return (
    <Modal.Header title={t('apply.title_apply_to_this_mission')} subtitle={t('apply.subtitle_email_to_identify')} />
  );
};
