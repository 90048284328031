import { Heading } from '@iziwork/design-system';
import styled from 'styled-components';

export const DescriptionWrapper = styled.div`
  width: 100%;
`;

export const DescriptionHeading = styled(Heading)`
  color: ${({ theme }) => theme.colors?.info?.normal};
  font-size: 20px;
  margin-bottom: 24px;
`;

export const DescriptionItem = styled.div`
  width: 100%;
  padding: 0;
  margin-bottom: ${({ theme }) => theme.space.layout.x.l};
  @media (min-width: ${({ theme }) => theme.devices.tablet.min}) {
    margin-bottom: ${({ theme }) => theme.space.layout.y.xl};
  }
`;

export const DescriptionText = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.16px;
  color: ${({ theme }) => theme.colors.body.text};
  @media (min-width: ${({ theme }) => theme.devices.tablet.min}) {
    font-size: 13px;
  }
`;
